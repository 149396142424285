const PublicRoutes = {
  searchData: "/Travel/get_searchData",
  getHotels: "/Travel/get_hotels",
  getAttractions: "/Travel/get_attractions",
  getRestaurants: "/Travel/get_restaurants",
  searchHotels: "https://tripadvisor16.p.rapidapi.com/api/v1/hotels/searchHotels",
  getHotelDetails: "https://tripadvisor16.p.rapidapi.com/api/v1/hotels/getHotelDetails",
  searchRestaurants: "https://tripadvisor16.p.rapidapi.com/api/v1/restaurant/searchRestaurants",
  getRestaurantDetails: "https://tripadvisor16.p.rapidapi.com/api/v1/restaurant/getRestaurantDetailsV2",
  getHeaderKeys: "/Travel/get_headerValues",
  getGeoLocationIds: "/Travel/get_geoLocationIds",
};

export default PublicRoutes;